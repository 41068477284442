.btn-add-quest {
    display:block;
    margin:0 auto;
    margin-top:15px;
    width:250px;
}

.btn-create-order {
    float:right;
    margin:15px 1px 9px 0;
}

.box-create-order input, .box-create-order select {
    text-align:center;
    margin:0 auto;
    margin-top:15px;
}

.box-orders {
    margin-top:15px;
}

.box-create-order input:first-of-type {
    margin-top:0;
}

.order-contents > span {
    display:table;
    margin:0 auto;
    font-size:14pt;
    font-style:italic;
}

.table-orders {
    margin:0 auto;
    margin-top:15px;
    margin-bottom:21px;
}

.table-orders td,
.table-orders th {
    text-align:center;
    padding:5px 15px 5px 15px;
}

.table-orders tr:nth-child(2n) td,
.table-contents tr:nth-child(2n) td {
    background:#eee;
}

.table-orders tr:hover:nth-child(2n) td,
.table-contents tr:hover:nth-child(2n) td,
.table-orders tr:hover:nth-child(1n) td,
.table-contents tr:hover:nth-child(1n) td {
    background:#000;
    color:#fff;
}