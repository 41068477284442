.box-login {
    display:table;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    text-align:center;
    background:url("./../../images/background-login.png");
    padding:15px;
}

.box-login, 
.box-login > input,
.btn-login {
    border:1px solid #fff;
}

.box-login > input,
.btn-login {
    display:block;
    width:250px;
    font-size:16pt;
    margin:0;
    padding:10px;
}

.box-login > input,
.error {
    margin-bottom:9px;
}

.box-login > input:focus {
    border:1px solid blue;
}

.btn-login {
    padding:10px 15px 10px 15px;
}

.btn-login:hover {
    opacity:0.7;
}

.box-login > .error {
    color:cyan;
    font-size:14pt;
}