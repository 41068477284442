.box-messages > .error {
    margin-left:15px;
}

ul.contact-info {
    display:block;
    list-style:none;
    padding:0;
    margin:0;
}

.messages tr {
    padding:0;
}

.messages {
    border-collapse: collapse;
}

.messages td {
    vertical-align:top;
    padding:15px 0 15px 0;
}

.messages th {
    font-size:16pt;
    padding:5px;
    background:#000;
    color:#fff;
}

.messages tr:nth-of-type(2n) {
    background:#eee;
}
.messages tr > td:nth-of-type(2),
.messages tr > th:nth-of-type(2) {
    padding-right:15px;
}
.messages tr > td:nth-of-type(3) {
    max-width:550px;
}

.messages tr > th:first-of-type,
.messages tr > td:first-of-type {
    padding-left:10px;
}

.messages tr th:last-of-type {
    padding-right:10px;
}

.messages p {
    margin-top:0;
}