.box-sql-response {
    resize:vertical;
    height:305px;
    overflow-y:scroll;
    font-family:"Consolas";
    color:Chartreuse;
    background:#2b2b2b;
    padding:15px;
}

.box-sql-response table{
    font-size:11pt;
}

.box-sql-response th {
    text-decoration:underline;
}

.textarea-sql {
    resize:vertical;
    width:100%;
    height:150px;
    padding:15px;
    border:1px solid #000;
    font-size:16pt;
}

.box-sql-response,
.textarea-sql {
    border-radius:3px;
}

.btn-execute {
    margin-top:15px;
    float:right;
}

.max-col-width {
    width:35px;
    text-align:center;
    display:inline-block;
    font-size:125%;
    margin:20px 0 0 15px;
}

.slider-col-width {
    width:50.5%;
    display:inline-block;
    margin-bottom:-5px;
    margin-left:25px;
    margin-right:10px;
}

.description {
    font-size:125%;
}

div.cell {
    margin:0px;
    padding:2px;
    display:block;
    max-width:250px;
    max-height:40px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    color:#ccc;
}