/* Global classes */
* {
    box-sizing:border-box;
}

#root {
    display:flex;
    flex-direction:row;
    background:url("./images/spacex.jpg");
    background-size:cover;
    background-position:center center;
    min-height:100vh;
}

nav {
    display:flex;
    flex-direction:column;
    width:9vw;
    border-right:1px solid black;
    background:#000;
}

nav > a {
    padding:10px 15px 10px 25px;
    text-decoration:none;
    color:#fff;
    transition:0.3s all;
}

nav > a:hover {
    background:#fff;
    color:#000;
}

nav > img {
    height:105px;
    margin:45px 5px 35px 5px;
}

nav > h4 {
    color:#fff;
    margin:0 0 2px 25px;
    font-style:italic;
    color:orange;
}

button {
    cursor:pointer;
}

th {
    text-align:left;
}

canvas {
    display:block;
}

input, select {
    display:block;
    width:250px;
    font-size:16pt;
    margin:0;
    padding:10px;
}

option {
    text-align:center;
}

fieldset {
    margin:0;
}

legend {
    font-size:16pt;
    margin:0;
}

table {
    font-size:16pt;
    border-collapse: collapse;
}

tr:hover:not(:first-child) {
    background:#000;
    color:#fff;
}

/* Helper classes */
.align-left { text-align:left; }
.align-right { text-align:right; }
.align-center { text-align:center; }

/* App.js stuff / app index */
.btn-power {
    position:absolute;
    top:43px;
    right:30px;
    width:45px;
    height:45px;
    padding:5px;
    font-size:22pt;
    color:orange;
    transition:0.3s all;
    border:1px solid orange;
    background:#000;
    border-radius:5px;
}

.btn-power:hover {
    color:#000;
    background:orange;
    border:1px solid #000;
}

.time-left {
    position:absolute;
    top:35px;
    right:120px;
    color:orange;
    font-size:33pt;
}

/* General purpose classes */
.btn {
    cursor:pointer;
    font-size:18pt;
    padding:5px;
    border-radius:3px;
    border:1px solid #000;
    transition:all 0.3s;
}

.btn:hover {
    background:#e1e1e1;
}

.btn-mini {
    font-size:12pt;
    padding:2px 5px 2px 5px;
}

.btn-dark {
    background:#000;
    color:#fff;
    border:1px solid #000;
}

.btn-dark:hover {
    background:#fff;
    color:#000;
    border:1px solid #000;
}

.btn-blank, .btn-blank:hover {
    background:none;
    color:#fff;
}

.content {
    width:91vw;
    padding:15px;
}

.box {
    background:#eee;
    padding:15px 25px 15px 15px;
    overflow:auto;
}

.box > h2 { margin:15px 0 5px 6px; }
.box > h3 { margin:12px 0 5px 9px; }
.box > h4 { margin:9px 0 5px 12px; }
.box > h5 { margin:6px 0 5px 15px; }

.box > header,
.box-inline > header,
.box-wide > header,
.box-half > header {
    background:#000;
    color:#fff;
    text-align:center;
    padding:5px;
    margin:-5px;
    margin-bottom:5px;
    font-size:16pt;
}

.box-inline,
.box-wide,
.box-half {
    display:inline-block;
    margin:5px 25px 5px 5px;
    background:#fff;
    padding:5px;
}

.box-inline > h2,
.box-inline > p,
.box-inline > button {
    margin:0;
}

.box-wide {
    display:block;
    background:#fff;
    padding:5px;
    width:100%;
    overflow:auto;
}

.box-half {
    display:block;
    background:#fff;
    padding:5px;
    width:100%;
    overflow:auto;
}

.box-inner {
    background:#eee;
    padding:5px;
}

.box-content {
    width:100%;
    height:100%;
    padding:15px;
}

.box-content > h1 {
    color:orange;
}

/* Misc general purpose classes */
.margin-top {
    margin-top:25px;
}