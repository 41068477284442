.btn-load {
    float:right;
    margin-top:15px;
}

ul.list-log {
    resize:vertical;
    height:305px;
    overflow-y:scroll;
    font-family:"Consolas";
    color:Chartreuse;
    background:#2b2b2b;
    padding:15px;
}

ul.list-log > li {
    list-style:none;
}

.canvas-system-load,
.canvas-socket-clients {
    border:1px solid black;
}